import React from 'react'
import { css } from '@emotion/react'
import { LayoutIR } from '../../../components/organisms/Layout/LayoutIR'
import { useLoadLocalScript } from '../../../components/hooks/useLoadLocalScript'
import { year } from '../../../components/styles/year'
import { pdfBtn01 } from '../../../components/styles/pdfBtn01'
import {
  selectMenuStyle,
  styleIRNews,
} from '../../../components/styles/styleIRNews'
import { SEOIR } from '../../../components/data/SEO'
import { ENGLISH_TITLE } from '../../../components/data/English'

// 投稿がない場合のCSS
const noPost = css`
  .news-list.ir-news {
    &::after {
      display: none;
    }
  }
`

const Page = () => {
  // ローカルのscriptタグを埋めたい場合
  useLoadLocalScript('/common/js/ir/XjStorageLoaderlibrary04.js')

  return (
    <LayoutIR
      heading="株主通信"
      headingEnglish={ENGLISH_TITLE.BusinessReport}
      BreadcrumbData={[
        { title: '株主・投資家の皆さまへ', url: '/ir' },
        { title: 'IRライブラリ', url: '/ir/library' },
        { title: '株主通信', url: '/' },
      ]}
    >
      <div className="pc:pt-12 pc:px-8 pb-12 bg-white" css={noPost}>
        <div css={selectMenuStyle}>
          <form action="#" method="post" name="form" id="form">
            <select
              css={year}
              name="year"
              id="xj-select-year_s"
              className="contact-select ir-select"
            ></select>
          </form>
          <div css={pdfBtn01}>
            <div css={styleIRNews} id="xj-mainlist"></div>
          </div>
        </div>
      </div>
    </LayoutIR>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => <SEOIR title="株主通信" url="/ir/library/reports" />
